var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-card",
                { attrs: { header: "Edit" } },
                [
                  _vm.saving
                    ? _c("b-spinner", [_vm._v("Saving...")])
                    : _vm._e(),
                  !_vm.saving
                    ? _c(
                        "b-form",
                        [
                          _vm._l(_vm.form.fields, function(field, name) {
                            return _c("form-field", {
                              key: name,
                              attrs: { name: name }
                            })
                          }),
                          _c(
                            "b-alert",
                            {
                              attrs: {
                                show: _vm.errorMsg !== "",
                                variant: "danger"
                              }
                            },
                            [_vm._v(_vm._s(_vm.errorMsg))]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: { disabled: _vm.disabledSave },
                              on: {
                                click: function($event) {
                                  return _vm.save()
                                }
                              }
                            },
                            [_vm._v("Save")]
                          ),
                          _vm.taskAttribute.ParentTaskAttributeID &&
                          (_vm.$root.$data.me.IsSuperUser ||
                            ["CP1", "CP2"].includes(
                              _vm.taskAttribute.ParentTaskAttribute
                                .BestServiceTag
                            ))
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "ml-3",
                                  attrs: { disabled: _vm.disabledSave },
                                  on: { click: _vm.saveAndGotoParent }
                                },
                                [_vm._v("Save & go to parent")]
                              )
                            : _vm._e(),
                          _c(
                            "b-button",
                            {
                              staticClass: "float-right",
                              attrs: { variant: "danger" },
                              on: { click: _vm.del }
                            },
                            [_vm._v("Delete")]
                          )
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }