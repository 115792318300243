<template lang="pug">
b-container(fluid)
  b-row 
    b-col(cols=12)
      b-card(header='Edit')
        b-spinner(v-if='saving') Saving...

        b-form(v-if='!saving')
          form-field(v-for="field, name in form.fields" :name="name" :key="name")

          b-alert(
            :show="errorMsg !== ''" 
            variant="danger"
          ) {{ errorMsg }}

          b-button(@click="save()" :disabled='disabledSave') Save
          b-button.ml-3(
            v-if=`
              taskAttribute.ParentTaskAttributeID && (
                $root.$data.me.IsSuperUser || 
                ['CP1', 'CP2'].includes(taskAttribute.ParentTaskAttribute.BestServiceTag)
              )
            `
            @click="saveAndGotoParent" 
            :disabled='disabledSave'
          ) Save &amp; go to parent
          b-button.float-right(variant='danger' @click="del") Delete
</template>

<script>

  export default {
    components: {
      FormField: () => import('@/components/FormFieldV4.vue'),
    },
    props: {
      taskAttribute: Object,
    },
    data () {
      return {
        saving: false,
        form: {
          values: {},
          model: this.taskAttribute,
          showValidationState: false,
          fields: {
            Description: {
              type: 'text',
              required: true,
            },
            /*
            SubProviderID: {
              label: 'Sub Provider',
              type: 'select',
              required: true,
              options: [],
              active: this.taskAttribute.ParentTaskAttributeID === null,
            },*/
            CompletionDateTime: {
              label: 'Due',
              type: 'datetime',
            },
            CompletionMinimumIntervalFromParent: {
              label: 'Minimum interval from parent',
              description: 'Minimum amount of time between this tasks completion date and the parent completion date',
              type: 'text',
              active: this.taskAttribute.ParentTaskAttributeID !== null
            },
            UserAccountID: {
              label: 'User',
              description: 'Person that is responsible for this task',
              type: 'select',
              options: this.getUsers
            },
            Status: {
              label: 'Status',
              type: 'select',
              options: [
                'Waiting',
                'Open',
                'In Progress',
                'On Hold',
                'Waiting for Manufacturing',
                'Completed',
                'Resolved',
              ],
            },
          },
        },
      }
    },
    provide () {
      return {
        'form': this.form,
        'formUpdate': this.updateForm,
      }
    },
    inject: [
      'setTaskInstance'
    ],
    watch: {
      taskAttribute () {
        //this.getSubProviders()
      }
    },
    methods: {
      updateForm(field, value) {
        this.$set(this.form.values, field, value)
      },
      async saveAndGotoParent () {
        await this.save({ 
          name: 'task-detail-overview',
          params: { id: this.taskAttribute.ParentTaskAttributeID },
        })
      },
      async save (nextRoute) {
        if (!nextRoute) nextRoute = { name: 'task-detail-overview' }
        if (Object.entries(this.form.values).length == 0) return
        this.saving = true

        const response = await this.$http.put(
          'task-attributes/' + this.taskAttribute.TaskAttributeID,
          this.form.values
        )

        this.setTaskInstance(response.data)
        this.form.values = {}

        this.$root.loadTasks()
        console.log(nextRoute)
        this.$router.push(nextRoute)
      },
      async del () {
        await this.$http.put(
          'task-attributes/' + this.taskAttribute.TaskAttributeID,
          {
            Status: 'Deleted'
          }
        )
        
        if (this.taskAttribute.ParentTaskAttributeID) {
          this.$router.push({ 
            name: 'task-detail-tasks', 
            params: {
              id: this.taskAttribute.ParentTaskAttributeID
            }
          })
        } else {
          this.$router.push({ 
            name: 'charly-projects-list'
          })
        }
        
      },
      getUsers () {
        return this.eligibleUsers
      },
      getSubProviders () {
        if (this.taskAttribute.ParentTaskAttributeID !== null) return

        this.$http
          .get('providers/'+this.taskAttribute.ProviderID+'/sub-providers', { 
            params: { 
              'allow-in-release-builder': 1,
              'currentPage': 1,
              'perPage': 10000,
            } 
          })
          .then(response => {
            this.form.fields.SubProviderID.options = response.data.map(sp => {
              return { value: sp.SubProviderID, text: sp.Description}
            })
          })
        return this.subProviders
      }
    },
    computed: {
      eligibleUsers () {
        return this.taskAttribute.EligibleUsers.map(user => {
          return { value: user.UserAccountID, text: user.FullName || user.EMail }
        })
      },
      errorMsg () {
        console.log(this.form.values.Status)
        const status = this.form.values.Status || this.taskAttribute.Status
        const date = this.form.values.CompletionDateTime || this.taskAttribute.CompletionDateTime
        if (
          !['Waiting', 'Completed', 'Resolved', 'Waiting for Manufacturing'].includes(status) && 
          !date
        )
          return 'When status is not Waiting, Completed or Resolved, a Due Date is mandatory'
        return ''
      },
      disabledSave () {
        if (Object.entries(this.form.values).length == 0) return true
        if (this.errorMsg !== '') return true

        return false
      },
    },
    mounted () {
      //this.getSubProviders()
    }
  }
</script>
